import React from 'react';

import { useAnalytics } from 'reaxl-analytics';

function HeaderContainer({ HeaderComponent, headerProps }) {
    const { sendClick, sendImpressions } = useAnalytics();

    const handleClick = (e, data) => {
        sendClick('headerGeneralEventHandler', e, { ...data });
    };

    const handleSponsorContentReady = (e, data) => {
        sendImpressions({
            name: 'headerGeneralEventHandler',
            data: {
                ...data,
                linkContext: 'PartnersImpression',
                originatedLocation: 'global-header',
            },
        });
    };

    return (
        <HeaderComponent
            {...headerProps}
            onClick={handleClick}
            onSponsorContentReady={handleSponsorContentReady}
        />
    );
}

export default HeaderContainer;
