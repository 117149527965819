import React from 'react';

import { AnalyticsProvider } from 'reaxl-analytics';
import { Header } from 'reaxl-header';

import globalAnalyticsHandlers from '@/analytics/globalAnalyticsHandlers';
import { withDataLayer } from '@/analytics/withDataLayer';

import HeaderContainer from '@/containers/HeaderContainer';

const EnhancedAnalyticsProvider = withDataLayer(AnalyticsProvider);

function AtcHeader({
    ...props
}) {
    return (
        <EnhancedAnalyticsProvider
            value={globalAnalyticsHandlers}
        >
            <HeaderContainer
                HeaderComponent={Header}
                headerProps={props}
            />
        </EnhancedAnalyticsProvider>
    );
}

export default AtcHeader;
