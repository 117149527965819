import React from 'react';

import { useFeatures } from 'reaxl-features';

import useDataLayer from './useDataLayer';

export const withDataLayer = (BaseComponent) => (props) => {
    const {
        disable_thirdparty: [disableThirdParty],
        google_analytics: [, { disableGtm, disableGtmEvents, delay }],
    } = useFeatures([
        'disable_thirdparty',
        'google_analytics',
    ]);

    const dataLayer = useDataLayer();

    return (
        <BaseComponent
            {...props}
            google={{
                delay,
            }}
            dataLayer={dataLayer}
            option={{
                disableGTM: disableGtm || disableThirdParty,
                disableGTMEvents: disableGtmEvents,
                disablePixall: disableThirdParty,
            }}
        />
    );
};

export default withDataLayer;
